<template>
  <div class="mine">
    <div class="top_sec">
      <div class="mine_top">
        <img
          v-if="userInfo && userInfo.logoUrl"
          class="mine_img"
          :height="180"
          :width="180"
          :src="userInfo.logoUrl"
        />
        <img v-else class="mine_img" :height="180" :width="180" src="" />
        <div class="mine_info">
          <span class="mine_name" v-if="userInfo && userInfo.logoUrl">{{
            userInfo.userName
          }}</span>
          <span class="mine_name" v-else>暂无昵称</span>
          <span class="mine_id" v-if="userInfo && userInfo.userId"
            >用户ID {{ userInfo.userId }}</span
          >
          <span class="mine_id" v-else>用户ID </span>

          <!-- <span class="mine_id">
            购买遇到问题请联系客房部，电话: 0572-26666880</span
          > -->
        </div>
      </div>
      <!-- <ul class="mine_list">
        <li class="mine_buy">
          <span class="mine_buy_num">5</span>
          <span class="mine_buy_txt">我买到的</span>
        </li>
        <li class="mine_buy">
          <span class="mine_buy_num">5</span>
          <span class="mine_buy_txt">我买到的</span>
        </li>
        <li class="mine_buy">
          <span class="mine_buy_num">5</span>
          <span class="mine_buy_txt">我买到的</span>
        </li>
        <li class="mine_buy">
          <span class="mine_buy_num">5</span>
          <span class="mine_buy_txt">我买到的</span>
        </li>
      </ul> -->
    </div>
    <div class="order_box" v-if="orderList.length != 0">
      <div class="mine-content-title">
        <span class="mine-contnet-line"></span>
        <span class="mine-contnet-line-txt">订单中心</span>
      </div>

      <van-tabs
        v-model="activeTab"
        color="#49A9F8"
        class="order_nav"
        title-active-color="#49A9F8"
        @change="onChange"
      >
        <van-tab name="all" title="全部订单"> </van-tab>
        <van-tab name="UnPaid" title="未支付"> </van-tab>
        <van-tab name="Paid" title="已支付"> </van-tab>
        <!-- <van-tab name="Delivered" title="已取货"></van-tab> -->
      </van-tabs>

      <!-- <van-tabbar
        active-color="#49A9F8"
        inactive-color="#000"
        v-model="active"
        class="order_nav"
        @change="onChange"
      >
        <van-tabbar-item name="all">
          <span>全部</span>
          <template #icon="props">
            <img :src="props.active ? icon.active : icon.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item name="UnPaid">
          <span>未支付</span>
          <template #icon="props">
            <img :src="props.active ? icon.active : icon.inactive" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item name="Paid">
          <span>已支付</span>
          <template #icon="props">
            <img :src="props.active ? icon.active : icon.inactive" />
          </template>
        </van-tabbar-item>
      </van-tabbar> -->

      <ul class="order_list">
        <li
          class="order_li"
          v-for="(item, index) in orderList"
          :key="item.order.id"
          :data-type="checkOrderType"
        >
          <div
            class="order_box"
            v-if="item.order.orderState == activeTab || activeTab == 'all'"
          >
            <div class="order_top">
              <span class="order_time">订单编号：{{ item.order.orderId }}</span>
              <div class="order_status">
                <!-- <span class="order_status_txt" v-if="item.order.orderState == 'Delivered'">商品已送达</span> -->
                <span
                  class="order_status_txt"
                  v-if="item.order.orderState == 'Paid'"
                  >已支付</span
                >
                <span
                  class="order_status_txt"
                  v-if="item.order.orderState == 'UnPaid'"
                  >未支付</span
                >
                <span class="order_status_line">|</span>
                <van-icon
                  class="delete_icon"
                  name="delete-o"
                  @click="deleteOrder(item.order.orderId, index)"
                />
              </div>
            </div>
            <div class="order_goods" @click="goodsDetail(index)">
              <div class="goods_img_box">
                <img class="goods_img" :src="item.product.iconUrl" />
                <!-- <img class="goods_img" :src="domain+item.product.iconUrl" /> -->
              </div>
              <div class="goods_info">
                <h4 class="goods_title">{{ item.product.name }}</h4>
                <div class="goods_time_num">
                  <!-- 08月20日取货 -->
                  <span class="goods_time">{{ item.order.createTime }}</span>
                  <span class="goods_num"
                    >x {{ item.order.productQuantity }}</span
                  >
                </div>
              </div>
            </div>
            <div class="good_total_txt">
              共{{ item.order.productQuantity }}件 应付总额：<span
                class="goods_total_price"
                >￥ {{ item.order.amount }}</span
              >
            </div>
            <div class="oerder_btn_box">
              <van-button
                class="order_btn"
                round
                type="info"
                size="mini"
                text="再次购买"
                color="#f8cf39"
                v-if="item.order.orderState == 'Delivered'"
              ></van-button>
              <!-- <van-button class="order_btn" round type="info" size="mini" text="" color="#f8cf39" v-if="item.order.orderState == 'Paid'"></van-button> -->
              <!-- <van-button class="order_btn" round type="info" size="mini" text="立即付款" color="#f8cf39" v-if="item.order.orderState == 'UnPaid'" @click="goToPay(index)"></van-button> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
    <h4 class="order_box_nocontent" v-if="orderList.length == 0">
      您还没有订单信息哦~
    </h4>
    <span class="concact"><van-icon name="service-o" /></span>
    <NAV />
    <div class="contact-info">
      购买遇到问题请联系客房部，电话: 0572-26666880
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
import NAV from "@/components/nav.vue";
import https from "@/api/https";

Vue.use(Lazyload);

let _that;
export default {
  name: "Mine",
  components: {
    NAV,
  },
  data() {
    return {
      userId: "",
      activeTab: "all",
      checkOrderType: "all",
      orderTitle: [
        {
          name: "all",
          title: "全部",
        },
        {
          name: "UnPaid",
          title: "待付款",
        },
        {
          name: "Paid",
          title: "待提货",
        },
        {
          name: "Delivered",
          title: "已提货",
        },
      ],

      userInfo: {},
      orderList: [],
      domain: "https://www.taiyiai11.com",
    };
  },
  created() {
    _that = this;
    console.log(document.cookie);
    console.log(localStorage.getItem("userId"));
    if (document.cookie.indexOf("userId") == -1) {
      this.userId = localStorage.getItem("userId");
    } else {
      this.userId = this.getCookie("userId");
    }
    this.getuserInfo();
    this.getorderList();
  },
  methods: {
    onChange(index) {
      console.log(index);
      this.checkOrderType = index;
    },
    getuserInfo() {
      https
        .fetchGet("/backend/user/home", { userId: this.userId })
        .then((res) => {
          if (res.data.status == 200) {
            _that.userInfo = res.data.data;
          }
          // this.getorderList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getorderList() {
      https
        .fetchGet("/backend/order/list", { userId: this.userId })
        .then((res) => {
          let _list = res.data.data;
          for (const key in _list) {
            _list[key]["order"]["createTime"] = new Date(
              parseInt(_list[key]["order"]["createTime"]) * 1000
            )
              .toLocaleString()
              .replace(/:\d{1,2}$/, " ")
              .split(" ")[0]
              .replace(/\//g, "-");
          }
          this.orderList = _list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteOrder(id, index) {
      console.log("删除订单id=" + id);
      if (confirm("确认要删除该订单吗？")) {
        https
          .fetchGet("/backend/order/delete", {
            userId: this.userId,
            orderId: id,
          })
          .then((res) => {
            if (res.data.status == 200) {
              alert("订单删除成功！");
              _that.orderList.splice(index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    goToPay(index) {
      // let _data = this.orderList[index].order.orderId;
      // let _urlData = "productId="+_data.product.productId+"&vendingMachineId="+_data.order.vendingMachineId+"&vendingMachineDoorId="+_data.order.vendingMachineDoorId;
      // https.fetchGet('/backend/order/pay_result', {orderId: this.orderList[index]["order"].orderId}).then((res) => {
      //   console.log(res.data.data);
      // }).catch(err=>{
      //   console.log(err)
      // })
      localStorage.setItem("orderId", this.orderList[index].order.orderId);
      this.$router.push({ path: "/payment" });
    },
    goodsDetail(index) {
      let _data = this.orderList[index];
      let _urlData =
        "productId=" +
        _data.product.productId +
        "&vendingMachineId=" +
        _data.order.vendingMachineId +
        "&vendingMachineDoorId=" +
        _data.order.vendingMachineDoorId;
      this.$router.push({ path: "/detail?" + _urlData });
    },
    getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== "") {
        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].trim();
          // 判断这个cookie的参数名是不是我们想要的
          if (cookie.substring(0, name.length + 1) === name + "=") {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
            break;
          }
        }
      }
      return cookieValue;
    },
  },
};
</script>

<style lang="less" scoped>
.mine {
  position: relative;
  height: 100%;
  // background: #f6f6f6;
  background-image: url("~@/assets/background.png");
  .top_sec {
    .mine_top {
      display: flex;
      justify-content: start;
      align-content: center;
      padding: 20px 15px 15px;
      // background: #f8cf39;
      .mine_img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      .mine_info {
        flex: 1;
        padding-left: 20px;
        color: rgba(68, 67, 67, 0.755);
        .mine_name {
          display: block;
          padding-top: 15px;
          padding-bottom: 4px;
          line-height: 20px;
          font-size: 14px;
        }
        .mine_id {
          display: block;
          font-size: 10px;
        }
      }
    }
    .mine_list {
      display: flex;
      justify-content: space-between;
      padding: 0 15px 30px;
      background: #f8cf39;
      text-align: center;
      color: #fff;
      .mine_buy {
        flex: 1;
        .mine_buy_num {
          display: block;
          font-weight: bold;
          font-size: 16px;
        }
        .mine_buy_txt {
          display: block;
          font-size: 10px;
        }
      }
    }
  }
  .order_box {
    // position: relative;
    padding-top: 10px;

    padding: 10px 15px 60px;
    background: rgba(255, 255, 255, 0.6);
    .mine-content-title {
      margin-bottom: 4px;
      .mine-contnet-line {
        display: inline-block;
        vertical-align: top;
        width: 15px;
        height: 16px;
        margin-right: 6px;
        // background: #f00;
        background-image: url("~@/assets/ordered.png");
        background-size: 15px 16px;
      }
      .mine-contnet-line-txt {
        display: inline-block;
        vertical-align: top;
        font-weight: bold;
        font-size: 14px;
        color: #101e28;
      }
    }

    .order_nav {
      // width: calc(100% - 24px);
      // position: absolute;
      // top: -10px;
      // left: 12px;
      margin: 0 12px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.6);
    }

    .order_list {
      padding: 10px 12px 52px;
      // background: rgba(255, 255, 255, 0.6);
      .order_li {
        .order_box {
          padding: 5px 10px;
          margin-bottom: 10px;
          border-radius: 8px;
          background: #fff;
          .order_top {
            display: flex;
            justify-content: space-between;
            height: 32px;
            line-height: 32px;
            border-bottom: 1px solid #eee;
            .order_time {
              flex: 1;
              font-size: 12px;
            }
            .order_status {
              display: flex;
              align-items: center;
              .order_status_txt {
                line-height: 16px;
                font-size: 13px;
              }
              .order_status_line {
                font-size: 14px;
                padding: 0 10px;
              }
              .delete_icon {
                font-size: 15px;
              }
            }
          }
          .order_goods {
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
            border-bottom: 1px solid #eee;
            .goods_img_box {
              width: 88px;
              height: 88px;
              margin-right: 8px;
              .goods_img {
                display: block;
                width: 100%;
                height: 100%;
              }
            }
            .goods_info {
              flex: 1;
              .goods_title {
                max-height: 32px;
                margin-bottom: 36px;
                font-weight: 500;
                line-height: 16px;
                font-size: 12px;
                color: #323233;
              }
              .goods_time_num {
                display: flex;
                font-size: 12px;
                .goods_time {
                  flex: 1;
                }
                .goods_num {
                  color: #969799;
                  .goods_total_price {
                    font-size: 14px;
                    color: #323233;
                  }
                }
              }
            }
          }
          .good_total_txt {
            padding-top: 15px;
            text-align: right;
            font-size: 12px;
            color: #969799;
          }
          .oerder_btn_box {
            padding-top: 10px;
            overflow: hidden;
            .order_btn {
              float: right;
              padding: 0 6px;
            }
          }
        }
      }
    }
  }
  .order_box_nocontent {
    margin: 0 auto;
    padding: 50px 0;
    background: rgba(255, 255, 255, 0.6);
    text-align: center;
  }
  // 客服
  .concact {
    position: fixed;
    right: 30px;
    top: 40px;
    width: 30px;
    height: 30px;
    background: #4aa6f19b;
    // background-image: url("~@/assets/concact.png");
    border-radius: 50%;
    line-height: 30px;
    text-align: center;
    color: #fff;
  }

  .contact-info {
    font-weight: bold; /* 使字体加粗，与“订单中心”标题相似 */
    font-size: 14px; /* 你可以根据需要调整这个值 */
    text-align: center; /* 使文字居中 */
    padding: 10px 0; /* 添加上下的间距，使其看起来更整齐 */
  }
}
</style>
